import React, { useCallback, useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { Routes } from '@shared/enums';
import { useToggleState } from '@shared/hooks/useToggleState';

import { useNavigate } from '@hooks/useNavigate';

import { TryzubIcon } from '@shared/icons/tryzub';
import { LogoIcon } from '@shared/icons/logo';

import { Button } from '@shared/ui/buttons/Button';
import { ButtonClose } from '@shared/ui/buttons/ButtonClose';

const useComponentState = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [isCloseAnimationPlayed, setIsCloseAnimationPlayed] = useState(false);
  const [isVisible, toggleIsVisible] = useToggleState(true);

  const onClose = useCallback(() => {
    setIsCloseAnimationPlayed(true);

    setTimeout(() => {
      toggleIsVisible();
    }, 2500);
  }, []);

  const onApprove = useCallback(async () => {
    toggleIsVisible();

    await navigate(Routes.Donate);
  }, [navigate]);

  return {
    t,
    isVisible: false,
    isCloseAnimationPlayed,
    onApprove,
    onClose,
    // TODO: replace on translation
    buttonText: t('transfer-help').split(' ')[0],
  } as const;
};

export const SupportUsModal = () => {
  const { t, buttonText, isVisible, isCloseAnimationPlayed, onApprove, onClose } = useComponentState();

  if (!isVisible) {
    return null;
  }

  return (
    <div
      className={clsx(
        'fixed right-2 lg:right-auto bottom-2 lg:bottom-4 left-2 lg:left-1/2 lg:ml-[-460px] z-overlay animate-support-us-fade-in flex items-start lg:items-center shadow-support-us bg-white lg:w-[920px] p-4 lg:px-6 lg:py-7',
        isCloseAnimationPlayed && 'animate-support-us-fade-out',
      )}
    >
      <div className="flex shrink-0 items-center justify-center bg-solitude w-[64px] h-[64px] lg:w-[80px] lg:h-[80px] mr-4 lg:mr-7 rounded-full">
        <TryzubIcon className="shrink-0 w-6 lg:w-[30px]" />
        <LogoIcon className="shrink-0 w-6 lg:w-[30px]" />
      </div>
      <div className="flex flex-col lg:flex-row mr-3 lg:mr-12">
        <div className="mb-2 lg:mb-0 lg:mr-6">
          <h3 className="text-base font-normal text-black mb-2">{t('support-us')}</h3>

          <p className="text-xs font-light text-black">{t('support-us-description')}</p>
        </div>

        <Button as="button" size="small" className="self-start lg:self-center" onClick={onApprove}>
          {buttonText}
        </Button>
      </div>
      <ButtonClose size={24} className="ml-auto" onClick={onClose} />
    </div>
  );
};
