import useMinWidthMediaQuery from '@hooks/useMinWidthMediaQuery';
import { useGetIconsOptimized } from '@shared/hooks/useGetIconsOptimized';
import { useGetSectionHeadingIcon } from '@shared/hooks/useGetSectionHeadingIcon';
import { ArrowRightIcon } from '@shared/icons/arrow-right';
import { IconHeader } from '@shared/ui/headers/icon';
import { Loader } from '@shared/ui/loader/Loader';
import { useGetHelpStatisticQuery } from '@store/api/landing.api';
import helpStatsRaw from '@values/help-stats';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import { StatsItem } from './item';

const SliderNavigation = () => {
  const swiper = useSwiper();

  return (
    <div className="flex justify-center mt-3.75 gap-x-3.75">
      <button type="button" onClick={() => swiper.slidePrev()}>
        <ArrowRightIcon className="rotate-180" />
      </button>

      <button type="button" onClick={() => swiper.slideNext()}>
        <ArrowRightIcon />
      </button>
    </div>
  );
};

export const HelpStats = () => {
  const { data, isLoading } = useGetHelpStatisticQuery();
  const imagesOprimized = useGetIconsOptimized();

  const { t } = useTranslation();
  const isExtraLargeScreen = useMinWidthMediaQuery('xl');
  const sizeVariant = isExtraLargeScreen ? 'xl' : 'sm';

  const helpStatData = data?.statistic ?? undefined;

  const helpStats = React.useMemo(() => {
    if (!helpStatData) return [];
    const helpStats: any = [];
    Object.entries(helpStatData).forEach(([key, _value], index) => {
      const value = key === 'volunteers' ? `> ${_value}` : `${_value} +`;
      const iconRawData = helpStatsRaw[index].icon[sizeVariant];
      const imageOptimized = imagesOprimized.find((v) => v.name === iconRawData.name);
      const icon = {
        ...iconRawData,
        content: imageOptimized.content,
      };
      const newEntry = { ...helpStatsRaw[index], icon, value };
      helpStats.push(newEntry);
    });

    return helpStats;
  }, [helpStatData, imagesOprimized, sizeVariant]);

  const sectionHeadingIcon = useGetSectionHeadingIcon('sign-of-the-horns', 'w-12 h-12');

  if (isLoading) return <Loader className="mx-auto" />;

  if (helpStats.length) {
    return (
      <div className="px-4 mb-3.75 md:mb-5.875 lg:mb-7.5 lg:px-0">
        <IconHeader icon={sectionHeadingIcon} text={t('help-stat') + '!'} />

        <Swiper
          slidesPerView={1}
          centeredSlides={true}
          loop
          breakpoints={{
            1024: {
              slidesPerView: 2,
              spaceBetween: 32,
            },
          }}
        >
          {helpStats.map((item) => (
            <SwiperSlide key={item.description}>
              <StatsItem
                color={item.color}
                valueColor={item.valueColor}
                icon={item.icon}
                value={item.value}
                description={item.description}
                buttonText={item.buttonText}
                buttonLink={item.buttonLink}
                linkState={item.linkState}
              />
            </SwiperSlide>
          ))}
          <span slot="container-end">
            <SliderNavigation />
          </span>
        </Swiper>
      </div>
    );
  }

  return null;
};
