import clsx from 'clsx';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Header, HeaderProps } from './index';

interface IconHeaderProps extends HeaderProps {
  icon: {
    alt: string;
    className?: string;
    image: any;
  };
}

export const IconHeader: React.FC<IconHeaderProps> = ({ icon = { className: '' }, text }) => {
  const { t } = useTranslation();

  return (
    <div className={clsx('relative mb-10 md:mb-3.75 lg:mb-4.875')}>
      <div className="flex justify-center h-3.75">
        <GatsbyImage image={getImage(icon.image)} alt={icon.alt} className={icon.className} />
      </div>
      <Header text={t(text)} className="md:text-4xl lg:text-5xl" />
    </div>
  );
};
