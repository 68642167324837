import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { GenericLink } from '@shared/ui/GenericLink';
import { IconHeader } from '@shared/ui/headers/icon';
import { useLanguage } from '@hooks/useLanguage';
import { useGetSectionHeadingIcon } from '@shared/hooks/useGetSectionHeadingIcon';

import aboutUsBlocks from '@values/about-us-blocks';
import { Color } from '@shared/types/common.types';

import { BaseBlock } from './block/base';

const hrefMsp = 'https://www.msp.gov.ua/';
const textMsp = 'spu-ministry-2';

const hrefMdt = 'https://thedigital.gov.ua/';
const textMdt = 'digital-ministry-2';

const hrefUndp = 'https://www.undp.org/ukraine';
const textUndp = 'undp-sweden';

const sendHelpLinks: Record<string, string> = {
  uk: 'https://easypay.ua/ua/partners/min-soc-pol-partners5',
  en: 'https://easypay.ua/en/partners/min-soc-pol-partners5',
};

export const AboutUs = () => {
  const { t } = useTranslation();
  const language = useLanguage();
  const sectionHeadingIcon = useGetSectionHeadingIcon('about-us', 'w-12 h-12');

  const paragraphs = useMemo(
    () => [
      <>
        {t('platform-established')}{' '}
        <GenericLink type="outer" to={hrefMsp} className="text-shamrock">
          {t(textMsp)}
        </GenericLink>{' '}
        {t('with-the-support')}{' '}
        <GenericLink type="outer" to={hrefMdt} className="text-shamrock">
          {t(textMdt)}
        </GenericLink>{' '}
        {t('and')}{' '}
        <GenericLink type="outer" to={hrefUndp} className="text-shamrock">
          {t(textUndp)}
        </GenericLink>{' '}
        {t('platform-goal')}
      </>,
      t('platform-main-goal'),
      t('we-will-win'),
    ],
    [language, t],
  );

  return (
    <section
      id="about-us"
      className="max-w-wrapper mx-auto p-4 pb-12 flex flex-col items-center lg:pb-[220px] xl:px-gutters bg-about-us-gradient"
    >
      <IconHeader icon={sectionHeadingIcon} text="about-us" />

      {/* TODO: fix responsive styles. */}
      <div className="flex flex-col lg:flex-row lg:mb-[48px]">
        <div className="basis-1/2 lg:basis-1/2 text-sm md:text-base lg:text-md lg:order-last">
          {paragraphs.map((children, index) => (
            <p key={index} className="mb-6 last:mb-0">
              {children}
            </p>
          ))}
        </div>
        <div className="mt-6 basis-1/2 lg:basis-1/2 shrink-0 min-h-[173px] sm:min-h-[282px] rounded-[20px] flex justify-center items-stretch p-8 bg-white lg:mt-0 lg:mr-8">
          <div className="max-w-[500px] animated-logos w-full bg-no-repeat bg-center" />
        </div>
      </div>

      {/* <div className="flex flex-col space-y-20 mt-20 md:space-y-0 md:mt-10 md:flex-row items-stretch md:space-x-20">
        {aboutUsBlocks.map((item, idx) => (
          <BaseBlock
            key={idx}
            header={item.header}
            description={item.description}
            icon={item.icon}
            color={item.color as Color}
            buttonText={item.buttonText}
            buttonLink={item.buttonLink(language)}
            className="basis-0 grow flex"
          />
        ))}
      </div> */}
    </section>
  );
};
