import * as React from 'react';
import clsx from 'clsx';

import { ArrowDownIcon } from '@shared/icons/arrow-down';
import { BaseText } from '@shared/ui/text/base';
import { Text } from '@shared/ui/text';

import { Element } from '@shared/interfaces/element.interface';

import { useToggle } from '@hooks/useToggle';

type AccordionButtonSize = 'small' | 'big';

interface AccordionButtonProps extends Element {
  html?: string;
  size?: AccordionButtonSize;
  summary?: string | React.ReactNode;
  defaultCollapsed?: boolean;
  panelClassName?: string;
  wrapperClassName?: string;
  onClick?: (collapsed: boolean) => void;
  children?: React.ReactNode;
}

// TODO: refactor
export const AccordionButton: React.FC<AccordionButtonProps> = ({
  defaultCollapsed = true,
  html = '',
  size = 'small',
  summary = '',
  className,
  panelClassName = '',
  wrapperClassName,
  children,
  onClick,
}) => {
  const [collapsed, toggleCollapsed] = useToggle({ defaultValue: defaultCollapsed });
  const onAccordionButtonClick = React.useCallback(() => {
    toggleCollapsed();

    if (!onClick) return;
    onClick(collapsed);
  }, [onClick, collapsed]);

  if (size === 'small') {
    return (
      <button
        className={clsx(
          'inline-flex items-center py-3 px-6 rounded-2xl hover:bg-black/[.07] active:bg-black/[.14] transition-colors',
          'focus:!bg-transparent',
          className,
        )}
        type="button"
        onClick={onAccordionButtonClick}
      >
        <BaseText align="center" className="mr-4">
          {summary}
        </BaseText>

        <ArrowDownIcon width={12} height={8} className={clsx('transition-transform', !collapsed && 'rotate-180')} />
      </button>
    );
  }

  const isStringSummary = typeof summary === 'string';

  return (
    <div
      className={clsx(
        'border-2 border-transparent rounded-lg overflow-hidden',
        wrapperClassName,
        !collapsed && '!border-solitude',
      )}
    >
      <button
        className={clsx(
          'inline-flex items-center justify-between py-8 px-6 w-full min-w-[20.5rem] hover:bg-alice-blue active:bg-gainsboro transition-colors',
          isStringSummary && 'lg:py-[3.125rem] lg:px-[2.5rem]',
          collapsed && 'bg-solitude',
          !collapsed && 'focus:!bg-transparent',
          className,
        )}
        type="button"
        onClick={onAccordionButtonClick}
      >
        {isStringSummary ? (
          <Text size="base" align="left" className="lg:text-3xl max-w-[80%]">
            {summary}
          </Text>
        ) : (
          <div className="text-left">{summary}</div>
        )}

        <ArrowDownIcon width={20} height={10} className={clsx('transition-transform', !collapsed && 'rotate-180')} />
      </button>

      <div
        className={clsx('home-q-answer px-6 pb-8 pt-4 text-base lg:text-md', html && 'lg:px-10', panelClassName)}
        hidden={collapsed}
        dangerouslySetInnerHTML={html ? { __html: html } : undefined}
      >
        {!html ? children : null}
      </div>
    </div>
  );
};
