import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import { SliderNavigation } from '@shared/ui/slider/slider-navigation';

import { PartnerItem } from '../partner';
import { PartnerImageConfig } from '../hooks/usePartnerImages';

interface ShopsPartnersCarouselProps {
  images: PartnerImageConfig[];
}

export const ShopsPartnersCarousel: React.FC<ShopsPartnersCarouselProps> = ({ images }) => {
  return (
    <Swiper
      spaceBetween={15}
      slidesPerView={1}
      loopAdditionalSlides={30}
      centeredSlides
      loop
      breakpoints={{
        480: {
          slidesPerView: 2,
        },
        768: {
          slidesPerView: 4,
        },
        1024: {
          slidesPerView: 4,
        },
        1280: {
          slidesPerView: 6,
        },
      }}
    >
      {images.map(({ name, url, image }) => (
        <SwiperSlide key={name}>
          <PartnerItem name={name} url={url} image={image as any} />
        </SwiperSlide>
      ))}

      <span slot="container-end">
        <SliderNavigation />
      </span>
    </Swiper>
  );
};
