import * as React from 'react';
import clsx from 'clsx';

import { Element } from '@shared/interfaces/element.interface';

import { Text } from '../text';

export interface HeaderProps extends Element {
  text: string;
}

export const Header: React.FC<HeaderProps> = ({ text, className }) => {
  return (
    <Text component="h2" size="4xl" align="center" className={clsx(className)}>
      {text}
    </Text>
  );
};
