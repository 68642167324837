import { useMemo } from 'react';
import { useGetIconsOptimized } from './useGetIconsOptimized';

export function useGetSectionHeadingIcon(iconName: string, iconClassName: string) {
  const imagesOprimized = useGetIconsOptimized();

  const sectionHeadingIcon = useMemo(() => {
    const image = imagesOprimized.find((v) => v.name === iconName);
    return {
      alt: 'icon',
      image: image.content,
      className: iconClassName,
    };
  }, [imagesOprimized]);

  return sectionHeadingIcon;
}
