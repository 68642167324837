import * as React from 'react';
import { IconProps } from '@shared/interfaces/icon.interface';

export const ArrowRightIcon: React.FC<IconProps> = ({ width = 56, height = 56, className }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`stroke-current ${className}`}
    >
      <path d="M11.666 28L44.3327 28" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M28 11.6666L44.3333 28L28 44.3333" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};
