import { HowToHelpType } from '@shared/enums';
import { User } from '@shared/types/common.types';

export default [
  {
    icon: {
      sm: {
        name: 'together-customer-volunteer-sm',
        className: 'w-[121px] h-[121px]',
      },
      xl: {
        name: 'together-customer-volunteer',
        className: 'w-[182px] h-[182px]',
      },
    },
    color: 'beige',
    valueColor: 'fuego',
    value: '> 583',
    description: 'became-volunteers',
    buttonText: 'i-want-be-too',
    buttonLink: (user: User | null) => '/cabinet/volunteer',
    linkState: { howToHelp: HowToHelpType.HandToHand },
  },
  {
    icon: {
      sm: {
        name: 'happy-customer-sm',
        className: 'w-[120px] h-[120px]',
      },
      xl: {
        name: 'happy-customer',
        className: 'w-[180px] h-[180px',
      },
    },
    color: 'dew',
    valueColor: 'shamrock',
    value: '1483 +',
    description: 'closed-personally',
    buttonText: 'help-more',
    buttonLink: (user: User | null) => '/cabinet/volunteer',
    linkState: { howToHelp: HowToHelpType.HandToHand },
  },
  {
    icon: {
      sm: {
        name: 'help-online-block-sm',
        className: 'w-[120px] h-[120px]',
      },
      xl: {
        name: 'help-online-block',
        className: 'w-[180px] h-[180px',
      },
    },
    color: 'solitude',
    valueColor: 'rich-blue',
    value: '3457 +',
    description: 'closed-online',
    buttonText: 'help-quickly',
    buttonLink: (user: User | null) => '/cabinet/volunteer',
    linkState: { howToHelp: HowToHelpType.OnlineFood },
  },
] as const;
