export default [
  {
    question: 'questions-1',
    answer: 'answer-1',
  },
  {
    question: 'questions-2',
    answer: 'answer-2',
  },
  {
    question: 'questions-3',
    answer: 'answer-3',
  },
  {
    question: 'questions-5',
    answer: 'answer-5',
  },
  {
    question: 'questions-6',
    answer: 'answer-6',
  },
  {
    question: 'question-category-silpo-15',
    answer: 'answer-category-silpo-15',
  },
  {
    question: 'questions-77',
    answer: 'answer-7',
  },
] as const;
