import * as React from 'react';
import { AccordionButton } from '@shared/buttons/accordion';
import { IconHeader } from '@shared/ui/headers/icon';
import partners from '@values/partners';
import useMinWidthMediaQuery from '@hooks/useMinWidthMediaQuery';
import { useToggle } from '@hooks/useToggle';
import usePartnerStyle from './hooks/usePartnerStyle';
import { useTranslation } from 'react-i18next';
import { PartnerItem } from './partner';
import { useGetSectionHeadingIcon } from '@shared/hooks/useGetSectionHeadingIcon';
import { usePartnerImages } from './hooks/usePartnerImages';

export const OurPartners = () => {
  /**
   * Is show all items at once or show them with accordion
   */
  const isWithAccordion = !useMinWidthMediaQuery('lg');

  const [showAll, toggleShowAll] = useToggle({ defaultValue: isWithAccordion });
  const [itemRef, itemStyle] = usePartnerStyle(isWithAccordion && showAll);
  const partnersSectionRef = React.useRef<HTMLDivElement>(null);
  const sectionHeadingIcon = useGetSectionHeadingIcon('handshake', 'w-12 h-12');

  const handleToggleAccordion = () => {
    toggleShowAll();
    if (!showAll) {
      partnersSectionRef.current!.scrollIntoView();
    }
  };

  const { t } = useTranslation();

  const partnersData = usePartnerImages(partners);

  return (
    <div
      id="our-partners"
      className="max-w-wrapper mx-auto flex flex-col items-center px-4 mb-3.75 md:mb-5.875 lg:mb-7.5 lg:px-gutters"
      ref={partnersSectionRef}
    >
      <IconHeader icon={sectionHeadingIcon} text="our-partners" />
      <div className="flex mb-8 flex-wrap items-center justify-center gap-x-8 gap-y-4 lg:px-8.375" style={itemStyle}>
        {partnersData.map(({ name, image, url }, idx) => (
          <PartnerItem key={name} name={name} url={url} image={image} innerRef={idx === 0 ? itemRef : null} />
        ))}
      </div>

      {isWithAccordion && (
        <AccordionButton
          summary={showAll ? t('show-more') : t('show-less')}
          defaultCollapsed={showAll}
          onClick={handleToggleAccordion}
        />
      )}
    </div>
  );
};
