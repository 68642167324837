import React from 'react';
import { useSwiper } from 'swiper/react';

import { ArrowRightIcon } from '@shared/icons/arrow-right';

export const SliderNavigation = () => {
  const swiper = useSwiper();

  return (
    <div className="flex justify-center mt-3.75 gap-x-3.75">
      <button type="button" onClick={() => swiper.slidePrev()}>
        <ArrowRightIcon className="rotate-180" />
      </button>

      <button type="button" onClick={() => swiper.slideNext()}>
        <ArrowRightIcon />
      </button>
    </div>
  );
};
