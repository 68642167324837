import { useUser } from '@shared/hooks/useUser';
import { getBackgroundColor, getTextColor } from '@shared/styles';
import { Color, User } from '@shared/types/common.types';
import { Button } from '@shared/ui/buttons/Button';
import { Text } from '@shared/ui/text';
import clsx from 'clsx';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

interface LinkState {
  [key: string]: any;
}
interface StatsItemProps {
  color: Color;
  valueColor: Color;
  icon: any;
  value: string;
  description: string;
  buttonText: string;
  buttonLink: (user: User | null) => string;
  linkState: LinkState | null;
}

export const StatsItem: React.FC<StatsItemProps> = ({
  color,
  valueColor,
  icon,
  value,
  description,
  buttonText,
  buttonLink,
  linkState,
}) => {
  const { t } = useTranslation();
  const [me] = useUser();

  return (
    <div
      className={clsx(
        `relative flex flex-col items-start max-w-[22rem] mx-auto lg:max-w-[808px] p-8 gap-y-4 rounded-lg lg:flex-row lg:justify-between lg:gap-y-0 lg:gap-x-6 xl:px-[56px] xl:py-[48px] xl:rounded-4xl`,
        getBackgroundColor(color),
      )}
    >
      <GatsbyImage image={getImage(icon.content)} alt="icon" className={`shrink-0 lg:order-2 ${icon.className}`} />
      <div className="flex flex-col gap-y-4 items-start lg:order-1">
        <Text className={clsx(getTextColor(valueColor), 'text-5xl')} weight="bold">
          {value}
        </Text>
        <Text size="xl" className="xl:text-2xl">
          {t(description)}
        </Text>
        <Button as="link-in" to={buttonLink(me)} state={linkState}>
          {t(buttonText)}
        </Button>
      </div>
    </div>
  );
};
