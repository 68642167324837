import * as React from 'react';

import { IconHeader } from '@shared/ui/headers/icon';

import { AccordionButton } from '@shared/buttons/accordion';
import { Button } from '@shared/ui/buttons/Button';

import questions from '@values/questions';
import { useTranslation } from 'react-i18next';
import { useGetSectionHeadingIcon } from '@shared/hooks/useGetSectionHeadingIcon';

export const Questions = () => {
  const { t } = useTranslation();
  const sectionHeadingIcon = useGetSectionHeadingIcon('light-bulb', 'w-[42px] h-[42px]');

  return (
    <div id="questions-answers" className="flex flex-col items-center mb-3.75 md:mb-5.875 lg:mb-7.5 px-4 lg:px-gutters">
      <IconHeader icon={sectionHeadingIcon} text="questions-and-answers" />
      <div className="flex flex-col w-full sm:w-[80%] lg:w-[100%] max-w-[68rem] gap-y-4 mb-6 lg:mb-3.125">
        {questions.map((item) => {
          const question: string = t(item.question);
          const answer: string = t(item.answer);

          if ('' === question || '' === answer) {
            return null;
          }

          return (<AccordionButton
            key={question}
            size="big"
            summary={question}
            html={answer}
            panelClassName="prose-a:text-shamrock prose-a:no-underline prose-a:cursor-pointer"
          />);
        })}
      </div>

      <Button as="link-in" to="/questions-answers">
        {t('more-answers')}
      </Button>
    </div>
  );
};
