import React from 'react';
import { HelpBlockProps } from '@widgets/how-to-help/block';

import config from '@config';

import { HowToHelpType } from '@shared/enums';
import { User } from '@shared/types/common.types';

import { SantaClausRulesLink } from '@widgets/santa-claus/rules-link';
import { SantaClausCustomerRules } from '@widgets/santa-claus/customer-rules';

const blocks = [
  {
    header: 'help-quickly-online',
    description: 'help-in-few-click',
    buttonText: 'give-help',
    color: 'solitude',
    icon: {
      name: 'help-online-block-216px',
    },
    buttonLink: () => '/cabinet/volunteer',
    linkState: { howToHelp: HowToHelpType.OnlineFood },
    items: [
      {
        text: 'select-request-and-amount',
        icon: {
          name: 'checkbox',
          className: 'w-[38px] h-[38px]',
        },
      },
      {
        text: 'buy-a-certificate',
        icon: {
          name: 'credit-card',
          className: 'w-[46px] h-[46px]',
        },
      },
      {
        text: 'person-can-pick-up-goods',
        icon: {
          name: 'shopping-bag',
          className: 'w-[44px] h-[44px]',
        },
      },
    ],
    languages: ['uk', 'en', 'de', 'pl', 'fr'],
  },
  {
    header: 'help-by-hands-action-card-title',
    description: 'help-around-personally',
    buttonText: 'give-help',
    color: 'dew',
    icon: {
      name: 'help-hand-block',
    },
    buttonLink: () => '/cabinet/volunteer',
    linkState: { howToHelp: HowToHelpType.HandToHand },
    items: [
      {
        text: 'choose-needs',
        icon: {
          name: 'checkbox',
          className: 'w-[38px] h-[38px]',
        },
      },
      {
        text: 'get-contact',
        icon: {
          name: 'camera-handshake',
          className: 'w-[44px] h-[49px]',
        },
      },
      {
        text: 'buy-goods',
        icon: {
          name: 'shopping-cart',
          className: 'w-[38px] h-[38px]',
        },
      },
      {
        text: 'give-hand-to-hand',
        icon: {
          name: 'handshake-small',
          className: 'w-[44px] h-[44px]',
        },
      },
      {
        text: 'confirm-request-closure',
        icon: {
          name: 'camera',
          className: 'w-[42px] h-[34px]',
        },
      },
    ],
    languages: ['uk'],
  },
  {
    header: 'get-help',
    description: 'leave-help-request',
    buttonText: 'get',
    color: 'beige',
    icon: {
      name: 'get-help-block',
    },
    buttonLink: (user: User | null) => (user ? '/cabinet/customer' : '/auth/sign-up'),
    linkState: null,
    items: [
      {
        text: 'leave-request',
        icon: {
          name: 'folded-hands',
          className: 'w-[38px] h-[38px]',
        },
      },
      {
        text: 'will-be-chosen-benefactor',
        icon: {
          name: 'checkbox',
          className: 'w-[38px] h-[38px]',
        },
      },
      {
        text: 'you-will-receive-help',
        icon: {
          name: 'camera-handshake',
          className: 'w-[44px] h-[49px]',
        },
      },
    ],
    languages: ['uk'],
  },
] as HelpBlockProps[];

if (config.features.santaClausLetter) {
  const santaClausLetterBlocks = [
    // {
    //   header: 'єМрія',
    //   description: 'santa-claus-letter',
    //   buttonText: 'go',
    //   color: 'gainsboro',
    //   icon: {
    //     name: 'write-letter-to-santa',
    //   },
    //   buttonLink: (user: User | null) => (user ? '/santa-claus/letter' : '/auth/sign-up'),
    //   linkState: null,
    //   items: [
    //     {
    //       component: <SantaClausCustomerRules />,
    //       icon: {
    //         name: 'santa-child',
    //       },
    //     },
    //     {
    //       text: 'Дитина має написати лист-мрію та прикріпити новорічний малюнок',
    //       icon: {
    //         name: 'list',
    //       },
    //     },
    //     {
    //       text: 'Почекай, можливо Миколай відгукнеться саме на твій лист-мрію',
    //       icon: {
    //         name: 'santa-claus',
    //       },
    //     },
    //     {
    //       text: 'Не засмучуватись, якщо Миколай не відгукнеться на твою мрію, можливо його помічники підготують для тебе новорічний сюрприз',
    //       icon: {
    //         name: 'prize',
    //       },
    //     },
    //     {
    //       component: (
    //         <SantaClausRulesLink type="customer" text="Заявки приймаються з 1 по 14 грудня 2022 року (включно)." />
    //       ),
    //       icon: {
    //         name: 'done',
    //       },
    //     },
    //   ],
    //   languages: ['uk'],
    // },
    {
      header: 'nicholas',
      description: 'get-letter-to-santa',
      buttonText: 'go',
      color: 'gainsboro',
      icon: {
        name: 'get-letter-to-santa',
      },
      buttonLink: () => '/cabinet/volunteer?category=santa-claus-letter',
      linkState: null,
      items: [
        {
          text: 'get-letter-to-santa-1',
          icon: {
            name: 'santa-claus',
          },
        },
        {
          text: 'get-letter-to-santa-2',
          icon: {
            name: 'list',
          },
        },
        {
          text: 'get-letter-to-santa-3',
          icon: {
            name: 'prize',
          },
        },
        {
          text: 'get-letter-to-santa-4',
          icon: {
            name: 'card',
          },
        },
        {
          component: <SantaClausRulesLink type="volunteer" text="get-letter-to-santa-5" />,
          icon: {
            name: 'done',
          },
        },
      ],
      languages: ['uk', 'en', 'de', 'pl', 'fr'],
    },
  ] as unknown as HelpBlockProps[];

  blocks.push(...santaClausLetterBlocks);
}

export default blocks;
