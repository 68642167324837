import * as React from 'react';

import { IconHeader } from '@shared/ui/headers/icon';
import helpBlocksRaw from '@values/help-blocks';

import { useLanguage } from '@hooks/useLanguage';
import { useGetSectionHeadingIcon } from '@shared/hooks/useGetSectionHeadingIcon';
import { HelpBlock } from './block';
import { useGetIconsOptimized } from '@shared/hooks/useGetIconsOptimized';
import useMinWidthMediaQuery from '@hooks/useMinWidthMediaQuery';
import clsx from 'clsx';

function useGetHelpHeadHeight() {
  const ref = React.useRef<HTMLDivElement | null>(null);
  const [height, setHeight] = React.useState('auto');

  React.useEffect(() => {
    function getHelpHeadHeight() {
      setHeight('auto');

      const helpBlockHeadElements = ref.current?.querySelectorAll('.help-block-head') || [];
      const elementsHeigts = Array.from(helpBlockHeadElements).map((element) => element.clientHeight);

      const elementsMaxHeight = Math.max(...elementsHeigts);
      setHeight(`${elementsMaxHeight}px`);
    }

    setTimeout(getHelpHeadHeight, 500);
    window.addEventListener('resize', getHelpHeadHeight);

    return () => {
      window.removeEventListener('resize', getHelpHeadHeight);
    };
  }, []);

  return [ref, height];
}

function useComponentState() {
  const language = useLanguage();
  const imagesOprimized = useGetIconsOptimized();
  const sectionHeadingIcon = useGetSectionHeadingIcon('thinking-face', 'w-[42px] h-[42px]');

  const [helpBlocksRef, helpHeadHeight] = useGetHelpHeadHeight();

  const helpBlocks = React.useMemo(() => {
    const filteredHelpBlocks = helpBlocksRaw.filter((block) => block.languages.includes(language));

    return filteredHelpBlocks.map((item) => {
      const headImageOptimized = imagesOprimized.find((v) => v.name === item.icon.name);
      const itemsOutput = item.items.map((_item) => {
        const iconOptimized = imagesOprimized.find((imageItem) => imageItem.name === _item.icon.name);
        return {
          text: _item.text,
          component: _item.component,
          icon: {
            ..._item.icon,
            image: iconOptimized.content,
          },
        };
      });

      return {
        ...item,
        items: itemsOutput,
        icon: headImageOptimized,
      };
    });
  }, [language, helpBlocksRaw]);

  return {
    helpBlocks,
    helpBlocksRef,
    helpHeadHeight,
    sectionHeadingIcon,
  };
}

export const HowToHelp = () => {
  const { helpBlocks, helpBlocksRef, helpHeadHeight, sectionHeadingIcon } = useComponentState();
  const isMinTableScreen = useMinWidthMediaQuery('md');

  return (
    <div id="how-to-help" className="px-4 max-w-wrapper mx-auto mb-3.75 md:mb-5.875 lg:mb-7.5 lg:px-gutters">
      {isMinTableScreen && (
        <div className="mb-5.875 lg:mb-8.375">
          <IconHeader icon={sectionHeadingIcon} text={'how-to-help'} />
        </div>
      )}
      <div
        className={clsx(
          'flex flex-col md:items-start md:flex-row md:flex-wrap md:-mx-4 justify-center gap-y-20',
          !isMinTableScreen && 'mt-4.875',
        )}
        ref={helpBlocksRef}
      >
        {helpBlocks.map((item, idx) => (
          <HelpBlock
            key={idx}
            header={item.header}
            description={item.description}
            icon={item.icon}
            color={item.color}
            items={item.items}
            buttonText={item.buttonText}
            buttonLink={item.buttonLink}
            linkState={item.linkState}
            helpHeadHeight={helpHeadHeight}
          />
        ))}
      </div>
    </div>
  );
};
