import useMinWidthMediaQuery from '@hooks/useMinWidthMediaQuery';
import { AboutUs } from '@widgets/about-us';
import { SupportUsModal } from '@widgets/support-us/support-us.modal';
// import { BannerSlider } from '@widgets/banners/banner-slider';
import { HelpStats } from '@widgets/help-stats';
import { HowToHelp } from '@widgets/how-to-help';
import { OurPartners } from '@widgets/our-partners';
import { ShopsPartners } from '@widgets/our-partners/shops';
import { Promo } from '@widgets/promo';
import { Questions } from '@widgets/questions';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

// import bannerItems from '@values/banners';

const HomePage = () => {
  const { t } = useTranslation();

  const isLargeScreen = useMinWidthMediaQuery('xl');
  const promoVariant = isLargeScreen ? 'row' : 'column';

  const isTabletAndBiggerScreen = useMinWidthMediaQuery('md');

  return (
    <>
      <h1 className="visually-hidden">{t('you-need-help') + ' ' + t('you-have-help')}</h1>
      {isTabletAndBiggerScreen && <Promo variant={promoVariant} />}
      <HowToHelp />
      {/* Hidden as unnecessary */}
      {/* <BannerSlider items={bannerItems} className="mb-3.75 md:mb-5.875 lg:mb-7.5" /> */}
      <Questions />
      <HelpStats />
      <OurPartners />
      <ShopsPartners />
      <AboutUs />
      <SupportUsModal />
    </>
  );
};

export default HomePage;
