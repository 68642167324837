import clsx from 'clsx';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from '@shared/ui/text';
import { BaseText } from '@shared/ui/text/base';
import { getBackgroundColor } from '@shared/styles';
import { Color } from '@shared/types/common.types';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

interface HelpBlockItemProps {
  index: number;
  text?: string;
  component?: React.ReactNode;
  color: Color;
  icon: {
    name: string;
    className: string;
    image: any;
  };
}

export const HelpBlockItem: React.FC<HelpBlockItemProps> = ({ text, component, color, index, icon }) => {
  const { t } = useTranslation();

  return (
    <div className={clsx('relative p-6 pr-7.5 rounded-lg', getBackgroundColor(color))}>
      <div>
        <Text component="p" size="lg" className="mb-1 lg:text-base">
          # {index}
        </Text>
        {text ? <BaseText className="pl">{t(text)}</BaseText> : null}
        {component ? <div className="pl text-sm">{component}</div> : null}
      </div>
      <div className="absolute top-3 right-6 flex justify-center items-center w-[72px] h-[72px] bg-white rounded-lg">
        <GatsbyImage image={getImage(icon.image)!} alt={icon.name} className={icon.className} />
      </div>
    </div>
  );
};
