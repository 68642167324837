import * as React from 'react';
import clsx from 'clsx';

import { Text, TextSize } from '@shared/ui/text';
import { useTranslation } from 'react-i18next';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

type PromoBlockRounded = 'full' | 'left' | 'right';
type PromoBlockVariant = 'sm' | 'xl';

interface IImage {
  image: any;
  className: string;
}
interface PromoBlockProps {
  texts: string[];
  images: IImage[];
  rounded: PromoBlockRounded;
  variant: PromoBlockVariant;
}

interface PromoBlockElements {
  root?: string;
  text?: string;
}

interface StyleConfig {
  rounded: Record<PromoBlockRounded, PromoBlockElements>;
  variant: Record<PromoBlockVariant, PromoBlockElements>;
}

const styleConfig: StyleConfig = {
  rounded: {
    full: {
      root: 'rounded-4xl',
    },
    left: {
      root: 'rounded-tl-4xl rounded-bl-4xl pl-[160px] pr-6',
    },
    right: {
      root: 'rounded-tr-4xl rounded-br-4xl pr-[160px] pl-7',
    },
  },
  variant: {
    sm: {
      root: 'w-full mx-auto px-8 py-3.75',
      text: '2xl',
    },
    xl: {
      root: 'py-3.125 px-0',
      text: '3xl',
    },
  },
};

export const PromoBlock: React.FC<PromoBlockProps> = ({ variant, rounded, images, texts }) => {
  const { t } = useTranslation();

  return (
    <div
      className={clsx(
        'relative inline-flex flex-col justify-center items-center max-w-[40rem] bg-white text-center text-',
        styleConfig.rounded[rounded].root,
        styleConfig.variant[variant].root,
      )}
    >
      <div className="absolute flex items-end bottom-full -mb-9">
        {images.map(({ image, className }) => (
          <GatsbyImage
            key={className}
            image={getImage(image)}
            alt="icon"
            className={`first:-mr-6 h-auto max-w-none ${className}`}
          />
        ))}
      </div>

      {texts.map((text) => (
        <Text key={text} size={styleConfig.variant[variant].text as TextSize} align="center" className="lg:text-4xl">
          {t(text)}
        </Text>
      ))}
    </div>
  );
};
