import { useToggle } from '@hooks/useToggle';
import { AccordionButton } from '@shared/buttons/accordion';
import { useUser } from '@shared/hooks/useUser';
import { getBackgroundColor } from '@shared/styles';
import { Color, Icon, User } from '@shared/types/common.types';
import { Button } from '@shared/ui/buttons/Button';
import { Subheader } from '@shared/ui/headers/sub';
import { Link } from '@shared/ui/Link';
import { BaseText } from '@shared/ui/text/base';
import clsx from 'clsx';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { HelpBlockItem } from './block-item';

interface HelpBlockItem {
  text?: string;
  component?: React.ReactNode;
  icon: {
    name: string;
    className: string;
  };
  className: string;
}
interface LinkState {
  [key: string]: any;
}

export interface HelpBlockProps {
  color: Color;
  secondaryColor: Color;
  header: string;
  description: string;
  buttonText: string;
  buttonLink: (user: User | null) => string;
  icon: any;
  items: HelpBlockItem[];
  linkState: LinkState | null;
  helpHeadHeight: number;

  languages: string[];
}

export const HelpBlock: React.FC<HelpBlockProps> = ({
  header,
  description,
  buttonText,
  color,
  icon,
  items,
  buttonLink,
  linkState,
  helpHeadHeight,
}) => {
  const { t } = useTranslation();
  const [collapsed, toggleCollapsed] = useToggle();
  const [me] = useUser();

  const helpHeadStyles = React.useMemo(() => ({ height: helpHeadHeight }), [helpHeadHeight]);

  return (
    <div className="basis-full md:basis-1/2 xl:basis-1/3 md:px-4">
      <div
        className={clsx(
          'help-block-head relative flex flex-col gap-5 items-center pt-4.875 px-6 pb-6 rounded-lg',
          getBackgroundColor(color),
        )}
        style={{ ...helpHeadStyles }}
      >
        <Link
          className="absolute bottom-full left-1/2 -translate-x-1/2 translate-y-1/2"
          to={buttonLink(me)}
          state={linkState}
        >
          <GatsbyImage image={getImage(icon.content)} alt="" className={'w-[113px] h-[113px]'} />
        </Link>
        <Subheader className="md:text-2xl lg:text-3xl">
          <Link to={buttonLink(me)} state={linkState}>
            {t(header)}
          </Link>
        </Subheader>
        <BaseText align="center" weight="light" className="lg:text-base">
          {t(description)}
        </BaseText>

        <Button as="link-in" size="small" to={buttonLink(me)} state={linkState} className="mt-auto">
          {t(buttonText)}
        </Button>

        <AccordionButton summary={t('details')} defaultCollapsed={collapsed} onClick={toggleCollapsed} />
      </div>

      <div className="flex flex-col gap-y-4 mt-6" hidden={collapsed}>
        {items.map(({ icon, text, component }, idx) => (
          <HelpBlockItem key={idx} color={color} index={idx + 1} icon={icon} text={text} component={component} />
        ))}
      </div>
    </div>
  );
};
