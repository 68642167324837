import { emptySplitApi } from '../emptySplitApi';

const extendedApi = emptySplitApi.injectEndpoints({
  endpoints: (build) => ({
    getHelpStatistic: build.query({
      query: () => ({ url: '/statistic/landing-page-statistic' }),
    }),
  }),
  overrideExisting: false,
});

export const { useGetHelpStatisticQuery } = extendedApi;
