import React from 'react';
import { useTranslation } from 'react-i18next';

import { GenericLink } from '@shared/ui/GenericLink';

import { useIsSpecificLanguage } from '@shared/hooks/useIsSpecificLanguage';

interface SantaClausRulesLinkProps {
  type: 'customer' | 'volunteer';

  text: string;
}

export const SantaClausRulesLink: React.FC<SantaClausRulesLinkProps> = ({ text, type }) => {
  const { t } = useTranslation();
  const isUkLanguage = useIsSpecificLanguage('uk');

  const rulesText = isUkLanguage ? 'Ознайомитись детальніше на сторінці за' : 'Read more details on the page';
  const linkText = isUkLanguage ? 'посиланням' : 'here';

  return (
    <div>
      {rulesText}{' '}
      <GenericLink type="page" className="text-shamrock hover:underline" to={`/santa-claus/${type}`}>
        {linkText}
      </GenericLink>
      . {t(text)}
    </div>
  );
};
