import React from 'react';
import { useTranslation } from 'react-i18next';

import { useGetSectionHeadingIcon } from '@shared/hooks/useGetSectionHeadingIcon';
import { IconHeader } from '@shared/ui/headers/icon';
import shops from '@values/shops';

import { ShopsPartnersCarousel } from './carousel';
import { usePartnerImages } from '../hooks/usePartnerImages';

export const ShopsPartners = () => {
  const { t } = useTranslation();
  const shopsImages = usePartnerImages(shops);

  const sectionHeadingIcon = useGetSectionHeadingIcon('shopping-bag', 'w-12 h-12');

  return (
    <div className="px-4 mb-3.75 md:mb-5.875 lg:mb-7.5 lg:px-0">
      <IconHeader icon={sectionHeadingIcon} text={t('shop-partners')} />

      <ShopsPartnersCarousel images={shopsImages} />
    </div>
  );
};
