import wbPartnerLogo from '@assets/images/partners/wb.svg';
import initiativaEktaPartnerLogo from '@assets/images/partners/initiativa-ekta.svg';
import uinnoPartnerLogo from '@assets/images/partners/uinno.svg';
import ukrainianCodePartnerLogo from '@assets/images/partners/ukrainian-code.svg';
import socarPartnerLogo from '@assets/images/partners/socar.svg';

export default [
  {
    name: 'The World Bank',
    url: 'https://www.worldbank.org/en/home',
    image: {
      name: 'wb',
      src: wbPartnerLogo,
    },
  },
  {
    name: 'Mastercard',
    url: 'https://www.mastercard.ua/uk-ua.html',
    image: {
      name: 'mastercard-partner',
    },
  },
  {
    name: 'Uinno',
    url: 'https://uinno.io/',
    image: {
      name: 'uinno',
      src: uinnoPartnerLogo,
    },
  },
  {
    name: 'EasyPay',
    url: 'https://easypay.ua/',
    image: {
      name: 'easy-pay',
      className: 'w-[118px] h-[47px]',
    },
  },
  {
    name: 'HELP UKRAINE 22. OPERATION PALYANYTSYA',
    url: 'https://helpukraine22.org/',
    image: {
      name: 'help-ukraine-22',
      className: 'w-[200px] h-[61px]',
    },
  },
  {
    name: 'Fozzy Group',
    url: 'https://www.fozzy.ua/ua/',
    image: {
      name: 'fozzy-group',
      className: 'w-[63px] h-[63px]',
    },
  },
  {
    name: 'WOG',
    url: 'https://wog.ua/',
    image: {
      name: 'wog',
      className: 'w-[120px]',
    },
  },
  {
    name: 'Fishka',
    url: 'https://myfishka.com/',
    image: {
      name: 'fishka',
      className: 'w-[120px]',
    },
  },
  {
    name: 'ATB',
    url: 'https://www.atbmarket.com/',
    image: {
      name: 'atb',
      className: 'w-[120px]',
    },
  },
  // {
  //   name: 'INITIATIVA EKTA',
  //   url: 'https://ektainitiative.com.ua/',
  //   image: {
  //     name: 'initiativa-ekta',
  //     src: initiativaEktaPartnerLogo,
  //     className: 'w-[152px] h-[51px]',
  //   },
  // },
  {
    name: 'UKRAINIAN CODE',
    url: '',
    image: {
      name: 'ukrainian-code',
      src: ukrainianCodePartnerLogo,
      className: 'w-[140px] h-[47px]',
    },
  },
  {
    name: 'ONECARD',
    url: 'https://onecard.gift/',
    image: {
      name: 'onecard',
      className: 'w-[140px]',
    },
  },
  {
    name: 'Socar',
    url: 'https://socar.ua/',
    image: {
      src: socarPartnerLogo,
      className: 'w-[80px]',
    },
  },
  {
    name: 'Нова Пошта',
    url: 'https://novaposhta.ua/',
    image: {
      name: 'nova-poshta',
      className: 'w-[150px]',
    },
  },
  // {
  //   name: 'Київстар ТБ',
  //   url: 'https://tv.kyivstar.ua/ua/',
  //   image: {
  //     name: 'ks-tb',
  //     className: 'w-[180px]',
  //   },
  // },
  // {
  //   name: 'Я і моя школа',
  //   url: 'https://meandmyschool.ua/',
  //   image: {
  //     name: 'me-and-school',
  //     className: 'w-[150px]',
  //   },
  // },
  {
    name: 'Аптека АНЦ',
    url: 'https://anc.ua/',
    image: {
      name: 'anc',
      className: 'w-[150px]',
    },
  },
];
