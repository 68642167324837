import * as React from 'react';
import clsx from 'clsx';

import { PromoBlock } from './block';

import needHelpImage from '@assets/images/need-help.png';
import readyToHelpImage from '@assets/images/ready-to-help.png';
import { useGetOptimizedImages } from './useGetOptimizedImages';

type PromoVariant = 'row' | 'column';

interface PromoProps {
  variant: PromoVariant;
}

const needHelpText = 'you-need-help';
const readyToHelpText = 'you-have-help';
// const images = [
//   { image: needHelpImage, className: 'relative z-10 w-[127px]' },
//   { image: readyToHelpImage, className: 'w-[137px]' },
// ];

function useComponentState({ variant }: PromoProps) {
  const [isColumn, isRow] = [variant === 'column', variant === 'row'];
  const optimizedImages = useGetOptimizedImages();
  const imagesOutput = React.useMemo(() => {
    const needHelpImage = optimizedImages.find((v) => v.name === 'need-help');
    const readyToHelpImage = optimizedImages.find((v) => v.name === 'ready-to-help');
    return [
      { image: needHelpImage.content, className: 'relative z-10 w-[127px]' },
      { image: readyToHelpImage.content, className: 'w-[137px]' },
    ];
  }, [optimizedImages]);

  return {
    isColumn,
    isRow,
    images: imagesOutput,
  };
}

export const Promo: React.FC<PromoProps> = (props) => {
  const { isColumn, isRow, images } = useComponentState(props);

  return (
    <div
      className={clsx(
        'flex justify-center px-4 bg-top bg-no-repeat bg-promo-pattern-mobile xl:bg-promo-pattern-desktop',
        isColumn && 'flex-col pt-10.625 pb-3.75',
        isRow && 'pt-[14rem] pb-[14rem]',
      )}
    >
      {isColumn && <PromoBlock variant="sm" rounded="full" texts={[needHelpText, readyToHelpText]} images={images} />}
      {isRow && (
        <>
          <PromoBlock variant="xl" rounded="left" texts={[needHelpText]} images={[images[0]]} />
          <PromoBlock variant="xl" rounded="right" texts={[readyToHelpText]} images={[images[1]]} />
        </>
      )}
    </div>
  );
};
