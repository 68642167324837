import * as React from 'react';

import { useClientDimensions } from '@hooks/useClientDimensions';

const ITEMS_TO_SHOW = 3;

/**
 * TODO: Investigate client dimensions
 *
 * (maybe need to add to client dimensions border's width), for now add this fixed offset
 */
const DIMENSION_OFFSET = ITEMS_TO_SHOW * 5;

/**
 * Hook for styling partner items' wrapper
 */
export default function usePartnerStyle(isShowOnlyFirst: boolean) {
  const partnerItemRef = React.useRef<HTMLDivElement>(null);
  const { height } = useClientDimensions(partnerItemRef);

  const partnerStyle = isShowOnlyFirst
    ? { overflow: 'hidden', height: `${(height + DIMENSION_OFFSET) * ITEMS_TO_SHOW}px` }
    : {};

  return [partnerItemRef, partnerStyle] as const;
}
